import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import { Container, Row, Col } from 'react-grid-system';
import Head from 'components/head';
import Logo from 'components/logo';
import Title from 'components/title';
import MarkdownWrapper from 'components/markdownwrapper';

const centerItems = {
  textAlign: 'center',
};

const agencyText = {
  textAlign: 'left',
  zIndex: '2',
};

const zindex = {
  zIndex: '2',
};

const Agency = ({ data }) => (
  <Layout>
    <Head pageTitle={data.agencyJson.title} />
    <Container fluid>
      <div className="cube-bg">
        <Row>
          <Container>
            <Col col={12} sm={12}>
              <div style={centerItems}>
                <Logo />
                <Title as="h1" size="large">
                  {data.agencyJson.title}
                </Title>
              </div>
            </Col>
            <div style={{ height: '5vh' }} />
            <Col md={8} push={{ md: 2 }} style={zindex}>
              <div style={agencyText}>
                <MarkdownWrapper>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.agencyJson.content.childMarkdownRemark.html,
                    }}
                  />
                </MarkdownWrapper>
              </div>
            </Col>
          </Container>
        </Row>
      </div>
    </Container>
  </Layout>
);

Agency.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Agency;

export const query = graphql`
  query AgencyQuery {
    agencyJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
